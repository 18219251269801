.DefaultStyles {
  box-shadow: none !important;
}

.inputXs {
  min-height: 0;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  height: 1.5rem;
}
.inputSm {
  min-height: 0;
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
  height: 2rem;
}
.inputMd {
  min-height: 0;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  height: 2.5rem;
}
.inputXl {
  min-height: 0;
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
  height: 3rem;
}
