.DefaultStyles {
  box-shadow: none !important;
}

.inputXs {
  min-height: 0;
  padding-top: 0.5rem !important;
}
.inputSm {
  min-height: 0;
  padding-top: 0.75rem !important;
}
.inputMd {
  min-height: 0;
  padding-top: 1rem !important;
}
.inputXl {
  min-height: 0;
  padding-top: 1.25rem !important;
}

.triggerXs {
  height: 1.5rem;
}

.triggerSm {
  height: 2rem;
}

.triggerMd {
  height: 2.5rem;
}

.triggerXl {
  height: 3rem;
}
