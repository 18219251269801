/*
Structure:
  .ToggleInput
*/

.ToggleInput {
}

.ToggleInput :global(input:checked ~ .dot) {
  @apply translate-x-full;
}

.ToggleInput :global(input:checked ~ .indicator) {
  @apply bg-orange-default;
}
